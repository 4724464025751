import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {useLocation} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    navBar: {
        marginRight: '25px',
        ".MuiGrid-item": {
            display: 'none',
        }
    },
    navBarItem: {
        padding: '30px 15px',
        '&:last-child' : {
            paddingRight: '10px',
            
        },
        '&.active': {
            borderTop: '4px solid #009FE3',
            paddingTop: '26px',
        },
        '& .c-mybje-panel-trigger': {
            '& span': {
                display: 'none',
                fontSize: '1rem',
                fontFamily: theme.typography.body1.fontFamily,
                '&.loginText': {
                    display: 'initial'
                }
            },
            '& svg': {
                display: 'none',
            }

        }
    },
    navBarItemLink: {
        color: '#858585',
    }
}));

export default function NavBar() {
    let location = useLocation();
    const classes = useStyles();
    
    return (
        <Grid item style={{flex: 1}}>
            <Grid
                className={classes.navBar}
                container
                justify="flex-end"
                alignItems="flex-start"
            >
                <Grid item className={`${classes.navBarItem} ${location.pathname === '/upload' ? 'active' : ''}`}>
                    <Link href="#/upload" disabled className={classes.navBarItemLink}>
                        Upload
                    </Link>
                </Grid>
                <Grid item className={`${classes.navBarItem} ${location.pathname === '/update' ? 'active' : ''}`}>
                    <Link href="#/update" className={classes.navBarItemLink}>
                        Update
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}
