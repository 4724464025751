import React from 'react'

import { PageTitle } from '@qmarketing/react-component-library'

import Grid from '@material-ui/core/Grid'

export default function Login(props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PageTitle>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            Terminal - Bedienstellenvisualisierung
                        </Grid>
                    </Grid>
                </PageTitle>
            </Grid>
        </Grid>
    )
}
