import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles';

import LogoImg from '../../assets/img/bje-logo.svg'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    logoImg: {
        width: '149px',
        height: '43px',
    },
}));

export default function Logo() {
    const classes = useStyles();
    return (
        <Grid item>
            <Box className="logo">
                <Link to="/" ><img src={LogoImg} alt="logo" className={classes.logoImg} /></Link>
            </Box>
        </Grid>
    );
}
