import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    footerBar: {
        marginRight: '25px',
        ".MuiGrid-item": {
            display: 'none',
        }
    },
    footerBarItem: {
        padding: '20px 15px',
        '&:last-child' : {
            paddingRight: '10px',
            
        },
    },
    footerBarItemLink: {
        color: '#000',
        fontSize: '13px',
    }
}));

export default function FooterBar() {
    const classes = useStyles();
    
    const brandDate = new Date()

    return (
        <Grid item style={{flex: 1}}>
            <Grid
                className={classes.footerBar}
                container
                justify="flex-end"
                alignItems="flex-start"
            >
                <Grid item className={classes.footerBarItem}>
                    <Link href="https://www.busch-jaeger.de/" className={classes.footerBarItemLink}>
                        © Busch-Jaeger {brandDate.getFullYear()}
                    </Link>
                </Grid>
                <Grid item className={classes.footerBarItem}>
                    <Link href="https://www.busch-jaeger.de/datenschutz" className={classes.footerBarItemLink}>
                        Datenschutz
                    </Link>
                </Grid>
                <Grid item className={classes.footerBarItem}>
                    <Link href="https://www.busch-jaeger.de/impressum" className={classes.footerBarItemLink}>
                        Impressum
                    </Link>
                </Grid>
                <Grid item className={classes.footerBarItem}>
                    <Link href="https://www.busch-jaeger.de/media/content/allgemein/2327_a_112015_Allgemeine_Verkaufsbedingungen_fuer_Erzeugnisse_und_Leistungen_der_Elektroindustrie.pdf" className={classes.footerBarItemLink}>
                        AGB
                    </Link>
                </Grid>
                <Grid item className={classes.footerBarItem}>
                    <Link href="https://www.busch-jaeger.de/kontakt" className={classes.footerBarItemLink}>
                        Kontakt
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}
