import React from 'react'
import { HashRouter as Router } from 'react-router-dom'

import { ThemeProvider } from '@qmarketing/react-component-library'

import Layout from '../layout'

export default function App() {
    
    return (
        <Router>
            <ThemeProvider>
                <Layout/>
            </ThemeProvider>
        </Router>
    )
}
