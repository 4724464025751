import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from './components/logo'
import Navbar from './components/navbar'
import {ErrorBoundary} from '@qmarketing/react-component-library'

export default function Header() {
    return (
        <ErrorBoundary>
            <Grid
                className="header"
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Logo />
                {/*<Navbar />*/}
            </Grid>
        </ErrorBoundary>
    );
}
