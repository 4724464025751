import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './components/header'
import Content from './components/staticContent'
import Footer from './components/footer';
import {ErrorBoundary} from '@qmarketing/react-component-library'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },
    root: {
        fontFamily: theme.typography.body1.fontFamily,
        height: '100%',
    },
    content: {
        width: '100%',
        flex: 1,
        overflow: 'auto',
    },
    item: {
        width: '100%'
    }
}));

export default function CenteredGrid() {
    const classes = useStyles();
    return (
        <ErrorBoundary>
            <Container className={`${classes.container} containerWhite`}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.root}
                    spacing={2}
                >
                    <Grid item className={classes.item} >
                        <Header />
                    </Grid>
                    <Grid item className={classes.content} >
                        <Content />
                    </Grid>
                    <Grid item className={classes.item} >
                        <Footer />
                    </Grid>
                </Grid>
            </Container>
        </ErrorBoundary>
    );
}
