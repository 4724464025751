import React from 'react';
import Grid from '@material-ui/core/Grid';
import FooterBar from './components/footerBar';
import {ErrorBoundary} from '@qmarketing/react-component-library'

export default function Footer() {
    return (
        <ErrorBoundary>
            <Grid
                className="header"
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <FooterBar />
            </Grid>
        </ErrorBoundary>
    );
}
